import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <iframe
        src="https://app.lemcal.com/@seaclub"
        title="External Website"
        width="100%"
        height={screenHeight} // Adjust the height as needed
      ></iframe>
    </div>
  );
}

export default App;
